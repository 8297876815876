import { authenticateWithGoogle } from '@/services/auth';

export default {
  data() {
    return {
      isSigningWithGoogle: false,
    };
  },
  mounted() {
    this.initGoogleButton();
  },
  methods: {
    initGoogleButton() {
      // eslint-disable-next-line no-undef
      google.accounts.id.initialize({
        client_id:
          process.env.VUE_APP_GOOGLE_CLIENT_ID ||
          '1019823495814-q2t2vm8f829ighceot8u46kile7shk00.apps.googleusercontent.com',
        callback: this.handleGoogleCredentialReponse,
      });
      // eslint-disable-next-line no-undef
      google.accounts.id.renderButton(
        document.getElementById('googleBtn'),
        { theme: 'outline', size: 'large', text: this.googleButtonText } // customization attributes
      );
    },
    async handleGoogleCredentialReponse(response) {
      this.isSigningWithGoogle = true;
      try {
        const res = await authenticateWithGoogle({
          token: response.credential,
        });

        console.log('google', res.data);
        const data = {
          tokenDetails: res.data.token,
          user: res.data.user,
        };
        this.onGoogleAuth(data);
      } catch (e) {
        console.log({ e });
        this.isSigningWithGoogle = false;
        this.$toast({
          title: 'An error occurred.',
          description: `Error while authenticating, please check your internet connectin and try again.`,
          status: 'error',
          position: 'top',
          duration: 3000,
        });
      }
    },
  },
};

import { render, staticRenderFns } from "./VerifyEmail.vue?vue&type=template&id=f1facaec"
import script from "./VerifyEmail.vue?vue&type=script&lang=js"
export * from "./VerifyEmail.vue?vue&type=script&lang=js"
import style0 from "./VerifyEmail.vue?vue&type=style&index=0&id=f1facaec&prod&lang=scss"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports

/* chakra-loader */
const installChakraComponents = require("!../../../node_modules/chakra-loader/lib/install-components.js")
installChakraComponents(component, {CHeading: require('@chakra-ui/vue').CHeading, CText: require('@chakra-ui/vue').CText, CInput: require('@chakra-ui/vue').CInput, CLink: require('@chakra-ui/vue').CLink, CStack: require('@chakra-ui/vue').CStack, CSpinner: require('@chakra-ui/vue').CSpinner, CButton: require('@chakra-ui/vue').CButton, CFlex: require('@chakra-ui/vue').CFlex})

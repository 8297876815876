<template>
  <div class="auth">
    <div class="auth__content">
      <c-stack
        :spacing="2"
        zIndex="20"
        :width="[
          '100%', // base
          '100%', // 480px upwards
          '100%', // 768px upwards
          '50%', // 768px upwards
        ]"
        mr="4"
      >
        <!-- <c-link
          display="flex"
          alignItems="center"
          w="max-content"
          as="router-link"
          to="/auth/login"
          fontSize="sm"
        >
          <svg
            v-chakra="{
              w: '1em',
              h: '1em',
              mr: '10px',
            }"
          >
            <use href="./../../assets/icons/back-arrow.svg#back-arrow"></use>
          </svg>
          Back to Sign in
        </c-link> -->
        <form class="auth__form" @submit.prevent="onSetPasswordFormSubmit">
          <c-heading color="#2D2D2D" as="h1" class="auth__form__title">
            Set Password
          </c-heading>
          <c-stack mb="2em" :spacing="3">
            <c-text fontSize="sm"> Enter your new password. </c-text>
            <c-input-group h="60px" size="lg">
              <c-input-left-element class="auth__form__input-icon" h="100%">
                <svg>
                  <use
                    href="./../../assets/icons/icon-lock.svg#icon-lock"
                  ></use>
                </svg>
              </c-input-left-element>
              <c-input
                borderRadius="10px"
                h="100%"
                :type="showPassword ? 'text' : 'password'"
                class="auth__form__input"
                placeholder="New Password"
                v-model="$v.setPasswordForm.password.$model"
              />
              <c-input-right-element class="auth__form__input-toggle">
                <c-link @click="showPassword = !showPassword">
                  {{ showPassword ? 'Hide' : 'Show' }}
                </c-link>
              </c-input-right-element>
            </c-input-group>
            <c-input-group h="60px" size="lg">
              <c-input-left-element class="auth__form__input-icon" h="100%">
                <svg>
                  <use
                    href="./../../assets/icons/icon-lock.svg#icon-lock"
                  ></use>
                </svg>
              </c-input-left-element>
              <c-input
                borderRadius="10px"
                h="100%"
                :type="showConfirmPassword ? 'text' : 'password'"
                class="auth__form__input"
                placeholder="Confirm New Password"
                v-model="$v.setPasswordForm.confirmPassword.$model"
              />
              <c-input-right-element class="auth__form__input-toggle">
                <c-link @click="showConfirmPassword = !showConfirmPassword">
                  {{ showConfirmPassword ? 'Hide' : 'Show' }}
                </c-link>
              </c-input-right-element>
            </c-input-group>
          </c-stack>
          <c-button
            w="100%"
            h="60px"
            borderRadius="lg"
            size="lg"
            variant="solid"
            type="submit"
            class="auth__form__button auth__form__button--primary"
            :disabled="$v.setPasswordForm.$invalid || isSettingPassword"
          >
            Set New Password
            <c-spinner
              ml="4"
              v-if="isSettingPassword"
              color="#fff"
              thickness="3px"
            />
          </c-button>
        </form>
      </c-stack>
      <c-flex direction="column">
        <HeroGraphic :image="'signin-graphic.webp'" :position="'right'" />
      </c-flex>
    </div>
  </div>
</template>

<script>
import { setPassword } from '@/services/auth';
// import { resetPassword } from '@/services/auth';
import { required, sameAs } from 'vuelidate/lib/validators';

import HeroGraphic from './components/HeroGraphic.vue';

export default {
  components: {
    HeroGraphic,
  },
  validations: {
    setPasswordForm: {
      password: {
        required,
      },
      confirmPassword: {
        required,
        sameAsPassword: sameAs('password'),
      },
    },
  },
  mounted() {
    this.resetToken = this.$route.query.reset_token;
  },
  data() {
    return {
      showPassword: false,
      showConfirmPassword: false,
      setPasswordForm: {
        password: null,
        confirmPassword: null,
      },
      isSettingPassword: false,
      resetToken: null,
    };
  },
  methods: {
    async onSetPasswordFormSubmit() {
      this.isSettingPassword = true;
      const password = this.setPasswordForm.password;
      try {
        await setPassword({ password, resetToken: this.resetToken });
        this.isSettingPassword = false;
        this.$router.push('/auth/login').then(() => {
          this.$toast({
            title: 'Success',
            description: 'Password changed, login to continue',
            status: 'success',
            position: 'top-right',
            duration: 2000,
          });
        });
      } catch (e) {
        this.$toast({
          title: 'Error',
          description: e.response.data.message,
          status: 'error',
          position: 'top-right',
          duration: 5000,
        });
        this.isSettingPassword = false;
      }
    },
  },
};
</script>

<style lang="scss" scoped>
.auth {
  &__form {
    @apply px-0 w-full;
  }
}
</style>

var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"auth"},[_c('div',{staticClass:"auth__content"},[_c('form',{staticClass:"auth__form",on:{"submit":function($event){$event.preventDefault();return _vm.onInviteFormSubmit.apply(null, arguments)}}},[_c('c-heading',{staticClass:"auth__form__title",attrs:{"color":"#2D2D2D","as":"h2"}},[_vm._v(" Welcome ")]),_c('c-text',{attrs:{"fontSize":"sm","mb":"3"}},[_vm._v(" Provide your name and set a new password to continue ")]),_c('c-stack',{attrs:{"mb":"2em","spacing":4,"is-inline":""}},[_c('c-box',{attrs:{"w":"50%"}},[_c('c-input-group',{attrs:{"h":"60px","size":"lg"}},[_c('c-input-left-element',{staticClass:"auth__form__input-icon",attrs:{"h":"100%"}},[_c('svg',[_c('use',{attrs:{"href":require("./../../assets/icons/icon-name.svg") + "#icon_name"}})])]),_c('c-input',{staticClass:"auth__form__input",attrs:{"borderRadius":"10px","h":"100%","type":"text","placeholder":"First name"},model:{value:(_vm.$v.newInviteForm.firstname.$model),callback:function ($$v) {_vm.$set(_vm.$v.newInviteForm.firstname, "$model", $$v)},expression:"$v.newInviteForm.firstname.$model"}})],1),(
              _vm.$v.newInviteForm.firstname.$dirty &&
              !_vm.$v.newInviteForm.firstname.required
            )?_c('c-text',{attrs:{"color":"vc-orange.400","fontSize":"xs","my":"1"}},[_vm._v(" Your first name is required ")]):_vm._e(),(
              _vm.$v.newInviteForm.firstname.$dirty &&
              !_vm.$v.newInviteForm.firstname.minLength
            )?_c('c-text',{attrs:{"color":"vc-orange.400","fontSize":"xs","my":"1"}},[_vm._v(" Your first name should be a minimum of 3 characters ")]):_vm._e()],1),_c('c-box',{attrs:{"w":"50%"}},[_c('c-input-group',{attrs:{"h":"60px","size":"lg"}},[_c('c-input-left-element',{staticClass:"auth__form__input-icon",attrs:{"h":"100%"}},[_c('svg',[_c('use',{attrs:{"href":require("./../../assets/icons/icon-name.svg") + "#icon_name"}})])]),_c('c-input',{staticClass:"auth__form__input",attrs:{"borderRadius":"10px","h":"100%","type":"text","placeholder":"Last name"},model:{value:(_vm.$v.newInviteForm.lastname.$model),callback:function ($$v) {_vm.$set(_vm.$v.newInviteForm.lastname, "$model", $$v)},expression:"$v.newInviteForm.lastname.$model"}})],1),(
              _vm.$v.newInviteForm.lastname.$dirty &&
              !_vm.$v.newInviteForm.lastname.required
            )?_c('c-text',{attrs:{"color":"vc-orange.400","fontSize":"xs","my":"1"}},[_vm._v(" Your last name is required ")]):_vm._e(),(
              _vm.$v.newInviteForm.lastname.$dirty &&
              !_vm.$v.newInviteForm.lastname.minLength
            )?_c('c-text',{attrs:{"color":"vc-orange.400","fontSize":"xs","my":"1"}},[_vm._v(" Your last name should be a minimum of 3 characters ")]):_vm._e()],1)],1),_c('c-box',[_c('c-input-group',{attrs:{"h":"60px","size":"lg"}},[_c('c-input-left-element',{staticClass:"auth__form__input-icon",attrs:{"h":"100%"}},[_c('svg',[_c('use',{attrs:{"href":require("./../../assets/icons/icon-lock.svg") + "#icon-lock"}})])]),_c('c-input',{staticClass:"auth__form__input",attrs:{"borderRadius":"10px","h":"100%","type":_vm.showPassword ? 'text' : 'password',"placeholder":"Password"},model:{value:(_vm.$v.newInviteForm.password.$model),callback:function ($$v) {_vm.$set(_vm.$v.newInviteForm.password, "$model", $$v)},expression:"$v.newInviteForm.password.$model"}}),_c('c-input-right-element',{staticClass:"auth__form__input-toggle"},[_c('c-link',{on:{"click":function($event){_vm.showPassword = !_vm.showPassword}}},[_vm._v(" "+_vm._s(_vm.showPassword ? 'Hide' : 'Show')+" ")])],1)],1),(
            _vm.$v.newInviteForm.password.$dirty &&
            !_vm.$v.newInviteForm.password.required
          )?_c('c-text',{attrs:{"color":"vc-orange.400","fontSize":"xs","my":"1"}},[_vm._v(" Password is required ")]):_vm._e(),(
            _vm.$v.newInviteForm.password.$dirty &&
            !_vm.$v.newInviteForm.password.minLength
          )?_c('c-text',{attrs:{"color":"vc-orange.400","fontSize":"xs","my":"1"}},[_vm._v(" Password should be a minimum of 8 characters ")]):_vm._e()],1),_c('c-button',{staticClass:"auth__form__button auth__form__button--orange",attrs:{"w":"100%","h":"60px","borderRadius":"lg","size":"lg","variant":"solid","type":"submit","disabled":_vm.$v.newInviteForm.$invalid || _vm.isUpdatingUser}},[(_vm.isUpdatingUser)?_c('c-spinner',{attrs:{"color":"#fff","thickness":"3px"}}):_c('c-text',{attrs:{"color":"#fff"}},[_vm._v("Continue ")])],1),_c('c-text',{attrs:{"mt":"10px","fontSize":"sm","textAlign":"center","color":"#495057"}},[_vm._v(" By clicking the button above you agree to our "),_c('c-link',{attrs:{"color":"#ef5323","fontWeight":"500"}},[_vm._v("Terms of Service")]),_vm._v(" and "),_c('c-link',{attrs:{"color":"#ef5323","fontWeight":"500"}},[_vm._v("Privacy Policy")])],1)],1),_c('c-flex',{attrs:{"direction":"column"}},[_c('HeroGraphic',{attrs:{"image":'signup-graphic.webp',"position":'right'}}),_c('c-text',{attrs:{"w":"75%","mx":"auto","textAlign":"center","fontStyle":"italic","fontWeight":"600","color":"vc-orange.400","display":{ base: 'none', lg: 'block' }}},[_vm._v(" The way to get started is to quit talking and begin doing ")]),_c('c-text',{attrs:{"mt":"5","mx":"auto","textAlign":"center","color":"vc-primary.400","fontStyle":"italic","display":{ base: 'none', lg: 'block' }}},[_vm._v(" Walt Disney ")])],1)],1)])
}
var staticRenderFns = []

export { render, staticRenderFns }
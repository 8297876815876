<template>
  <div class="auth">
    <div class="auth__content">
      <form @submit.prevent="onFormSubmit" class="auth__form">
        <c-heading color="#2D2D2D" as="h2" class="auth__form__title">
          Verification code
        </c-heading>
        <c-stack :spacing="10">
          <c-stack :spacing="2">
            <c-text
              >We just emailed you on, if you can't find it, check your email's
              spam folder</c-text
            >
            <c-input
              borderRadius="10px"
              h="60px"
              type="text"
              class="auth__form__input auth__form__input--success"
              :value="tempUser.email"
              readonly
              size="lg"
              color="#21ae8c"
              borderColor="#21ae8c"
              fontWeight="600"
            />
            <c-link
              fontSize="xs"
              fontWeight="500"
              color="#ef5323"
              @click="resendEmail"
            >
              Didn’t receive our mail? Send again
            </c-link>
          </c-stack>
          <c-stack :spacing="2">
            <c-text>Please enter the code we just sent you</c-text>
            <c-input
              borderRadius="10px"
              h="60px"
              type="number"
              class="auth__form__input"
              placeholder="Confirmation code"
              size="lg"
              v-model.number="$v.verificationCodeForm.verificationCode.$model"
            />
          </c-stack>
        </c-stack>
        <c-button
          w="100%"
          h="60px"
          borderRadius="lg"
          size="lg"
          variant="solid"
          type="submit"
          :disabled="
            $v.verificationCodeForm.$invalid ||
            isVerifyingEmail ||
            isResendingEmail
          "
          class="auth__form__button auth__form__button--orange"
        >
          <c-spinner
            v-if="isVerifyingEmail || isResendingEmail"
            color="#fff"
            thickness="3px"
          />
          <c-text v-else color="#fff">Confirm </c-text>
        </c-button>
      </form>
      <c-flex direction="column">
        <HeroGraphic :image="'signin-graphic.webp'" :position="'right'" />
      </c-flex>
    </div>
  </div>
</template>

<script>
import { mapState, mapActions } from 'vuex';

import { verifyEmail, resendVerificationEmail } from '@/services/auth.js';

import { required, minLength, maxLength } from 'vuelidate/lib/validators';
import { onLogin } from '@/vue-apollo.js';

import config from '@/mixins/config.js';

import HeroGraphic from './components/HeroGraphic.vue';

export default {
  mixins: [config],
  components: {
    HeroGraphic,
  },
  mounted() {
    this.verificationCodeForm.email = this.tempUser.email;
  },
  data() {
    return {
      isVerifyingEmail: false,
      isResendingEmail: false,
      verificationCodeForm: {
        email: null,
        verificationCode: null,
      },
    };
  },
  validations: {
    verificationCodeForm: {
      email: {},
      verificationCode: {
        required,
        minLength: minLength(6),
        maxLength: maxLength(6),
      },
    },
  },
  computed: {
    ...mapState('auth', {
      tempUser: (state) => state.tempUser,
    }),
  },
  methods: {
    ...mapActions({
      setUser: 'auth/setUser',
      setToken: 'auth/setToken',
    }),
    onFormSubmit() {
      this.isVerifyingEmail = true;
      const form = {
        ...this.verificationCodeForm,
        verificationCode: +this.verificationCodeForm.verificationCode,
      };
      verifyEmail(form)
        .then(async (res) => {
          if (res.data.ResponseCode === '03') {
            this.$toast({
              title: 'Message',
              description: res.data.ResponseDescription,
              status: 'success',
              position: 'top',
              duration: 3000,
            });
          } else {
            this.setToken(res.data.token);
            this.setUser(res.data.user);
            await onLogin(this.$apollo.provider.defaultClient, res.data.token);
          }
          this.getGlobalConfig();
          this.isVerifyingEmail = false;
          this.$router.push('/onboarding');
        })
        .catch((e) => {
          this.isVerifyingEmail = false;
          const errorMessage = e.response
            ? e.response.data.message
            : 'Please Try again';
          this.$toast({
            title: 'An error occurred.',
            description: errorMessage,
            status: 'error',
            position: 'top',
            duration: 3000,
          });
        });
    },
    resendEmail() {
      this.isResendingEmail = true;
      resendVerificationEmail({ email: this.tempUser.email })
        .then(() => {
          this.isResendingEmail = false;
          this.$toast({
            title: 'Success',
            description: `Verification code has been sent to ${this.tempUser.email}`,
            status: 'success',
            position: 'top',
            duration: 3000,
          });
        })
        .catch((e) => {
          this.isResendingEmail = false;
          const errorMessage = e.response
            ? e.response.data.message
            : 'Please Try again';
          this.$toast({
            title: 'An error occurred.',
            description: errorMessage,
            status: 'error',
            position: 'top',
            duration: 3000,
          });
        });
    },
  },
};
</script>

<style lang="scss">
.auth {
  &__left {
    background-image: linear-gradient(
        to right top,
        rgba(0, 0, 0, 0.7),
        rgba(0, 0, 0, 0.7)
      ),
      url('./../../assets/img/auth-left-female.jpg');
  }
}
</style>

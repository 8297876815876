var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"auth"},[_c('div',{staticClass:"auth__content"},[_c('c-stack',{attrs:{"spacing":2,"zIndex":"20","width":[
        '100%', // base
        '100%', // 480px upwards
        '100%', // 768px upwards
        '50%', // 768px upwards
      ],"mr":"2"}},[_c('c-link',{attrs:{"display":"flex","alignItems":"center","w":"max-content","as":"router-link","to":"/auth/login","fontSize":"sm"}},[_c('svg',{directives:[{name:"chakra",rawName:"v-chakra",value:({
            w: '1em',
            h: '1em',
            mr: '10px',
          }),expression:"{\n            w: '1em',\n            h: '1em',\n            mr: '10px',\n          }"}]},[_c('use',{attrs:{"href":require("./../../assets/icons/back-arrow.svg") + "#back-arrow"}})]),_vm._v(" Back to Sign in ")]),_c('form',{staticClass:"auth__form",on:{"submit":function($event){$event.preventDefault();return _vm.onForgotPasswordFormSubmit.apply(null, arguments)}}},[_c('c-heading',{staticClass:"auth__form__title",attrs:{"color":"#2D2D2D","as":"h1"}},[_vm._v(" Forgot Password ")]),_c('c-stack',{attrs:{"mb":"2em","spacing":3}},[_c('c-text',{attrs:{"fontSize":"sm"}},[_vm._v(" Enter your email address, we will send a reset password link ")]),_c('c-input-group',{attrs:{"h":"60px","size":"lg"}},[_c('c-input-left-element',{staticClass:"auth__form__input-icon",attrs:{"h":"full"}},[_c('svg',[_c('use',{attrs:{"href":require("./../../assets/icons/icon-mail.svg") + "#icon_mail"}})])]),_c('c-input',{staticClass:"auth__form__input",attrs:{"borderRadius":"10px","h":"full","type":"email","placeholder":"Enter your email address"},model:{value:(_vm.$v.forgotPasswordForm.email.$model),callback:function ($$v) {_vm.$set(_vm.$v.forgotPasswordForm.email, "$model", $$v)},expression:"$v.forgotPasswordForm.email.$model"}})],1)],1),_c('c-button',{staticClass:"auth__form__button auth__form__button--primary",attrs:{"w":"100%","h":"60px","borderRadius":"lg","size":"lg","variant":"solid","type":"submit","disabled":_vm.$v.forgotPasswordForm.$invalid || _vm.isResettingPassword}},[_vm._v(" Reset Password "),(_vm.isResettingPassword)?_c('c-spinner',{attrs:{"ml":"4","color":"#fff","thickness":"3px"}}):_vm._e()],1)],1)],1),_c('c-flex',{attrs:{"direction":"column"}},[_c('HeroGraphic',{attrs:{"image":'signin-graphic.webp',"position":'right'}})],1)],1)])
}
var staticRenderFns = []

export { render, staticRenderFns }
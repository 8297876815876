<template>
  <div
    v-bind="$attrs"
    class="hero__graphic"
    :class="[`hero__graphic--${position}`]"
  >
    <div class="hero__graphic__circle">
      <span
        class="hero__graphic__circle__node hero__graphic__circle__node--shuttle"
      >
        <svg>
          <use href="@/assets/icons/shuttle.svg#shuttle"></use>
        </svg>
      </span>
      <span
        class="hero__graphic__circle__node hero__graphic__circle__node--briefcase"
      >
        <svg>
          <use href="@/assets/icons/briefcase.svg#briefcase"></use>
        </svg>
      </span>
    </div>
    <picture>
      <source :srcset="require('@/assets/img/' + image + '')" />
      <img
        :src="require('@/assets/img/' + image.replace('webp', 'png') + '')"
        :class="['hero__graphic__image', `hero__graphic__image--top-${top}`]"
        alt="Vibrant Future hero image"
      />
    </picture>
  </div>
</template>

<script>
export default {
  props: {
    image: {
      type: String,
      default: '',
    },
    top: {
      type: String,
      default: '',
    },
    position: {
      type: String,
      default: 'left',
    },
  },
};
</script>

<style lang="scss">
.hero {
  &__graphic {
    @apply hidden;
  }
  @screen sm {
    &__graphic {
      @apply hidden relative items-center justify-center;
      // &--left {
      //   @apply mr-10;
      // }
      // &--right {
      //   @apply ml-10;
      // }
      &__circle {
        @apply relative border border-secondary border-opacity-20 rounded-full;
        &__node {
          @apply absolute bg-white shadow-md rounded-full p-4;
          &--shuttle {
            @apply top-3 left-2;
          }
          &--briefcase {
            @apply bottom-3 right-3;
          }
          svg {
            @apply w-full h-full;
          }
        }
      }
      &__image {
        @apply w-3/4 absolute left-1/2 transform -translate-x-1/2 -translate-y-1/2;
      }
    }
  }
  @screen md {
    &__graphic {
      width: 22rem;
      height: 22rem;
      &__circle {
        width: 90%;
        height: 90%;
        &__node {
          @apply w-14 h-14 p-4;
          &--shuttle {
            /* @apply ; */
          }
          &--briefcase {
            /* @apply; */
          }
          svg {
            /* @apply; */
          }
        }
      }
      &__image {
        top: 9rem;
      }
    }
  }
  @screen lg {
    &__graphic {
      @apply flex;
      width: 30rem;
      height: 30rem;
      &__circle {
        &__node {
          @apply w-20 h-20 p-5;
        }
      }
      &__image {
        top: 11.5rem;
      }
    }
  }
  @screen xl {
    &__graphic {
      width: 35rem;
      height: 35rem;
      // &--left {
      //   @apply mr-44;
      // }
      // &--right {
      //   @apply ml-44;
      // }
      &__circle {
        &__node {
          @apply w-20 h-20 p-6;
          &--shuttle {
            @apply top-5 left-8;
          }
          &--briefcase {
            @apply bottom-6 right-7;
          }
        }
      }
      &__image {
        top: 13.5rem;
        &--top-md {
          top: 18rem;
        }
      }
    }
  }
}
</style>

import { render, staticRenderFns } from "./SetNewPassword.vue?vue&type=template&id=256a55f6&scoped=true"
import script from "./SetNewPassword.vue?vue&type=script&lang=js"
export * from "./SetNewPassword.vue?vue&type=script&lang=js"
import style0 from "./SetNewPassword.vue?vue&type=style&index=0&id=256a55f6&prod&lang=scss&scoped=true"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "256a55f6",
  null
  
)

export default component.exports

/* chakra-loader */
const installChakraComponents = require("!../../../node_modules/chakra-loader/lib/install-components.js")
installChakraComponents(component, {CHeading: require('@chakra-ui/vue').CHeading, CText: require('@chakra-ui/vue').CText, CInputLeftElement: require('@chakra-ui/vue').CInputLeftElement, CInput: require('@chakra-ui/vue').CInput, CLink: require('@chakra-ui/vue').CLink, CInputRightElement: require('@chakra-ui/vue').CInputRightElement, CInputGroup: require('@chakra-ui/vue').CInputGroup, CStack: require('@chakra-ui/vue').CStack, CSpinner: require('@chakra-ui/vue').CSpinner, CButton: require('@chakra-ui/vue').CButton, CFlex: require('@chakra-ui/vue').CFlex})

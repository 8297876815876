<template>
  <div class="auth">
    <div class="auth__content">
      <c-stack
        :spacing="2"
        zIndex="20"
        :width="[
          '100%', // base
          '100%', // 480px upwards
          '100%', // 768px upwards
          '50%', // 768px upwards
        ]"
        mr="2"
      >
        <c-link
          display="flex"
          alignItems="center"
          w="max-content"
          as="router-link"
          to="/auth/login"
          fontSize="sm"
        >
          <svg
            v-chakra="{
              w: '1em',
              h: '1em',
              mr: '10px',
            }"
          >
            <use href="./../../assets/icons/back-arrow.svg#back-arrow"></use>
          </svg>
          Back to Sign in
        </c-link>
        <form class="auth__form" @submit.prevent="onForgotPasswordFormSubmit">
          <c-heading color="#2D2D2D" as="h1" class="auth__form__title">
            Forgot Password
          </c-heading>
          <c-stack mb="2em" :spacing="3">
            <c-text fontSize="sm">
              Enter your email address, we will send a reset password link
            </c-text>
            <c-input-group h="60px" size="lg">
              <c-input-left-element class="auth__form__input-icon" h="full">
                <svg>
                  <use
                    href="./../../assets/icons/icon-mail.svg#icon_mail"
                  ></use>
                </svg>
              </c-input-left-element>
              <c-input
                borderRadius="10px"
                h="full"
                type="email"
                class="auth__form__input"
                placeholder="Enter your email address"
                v-model="$v.forgotPasswordForm.email.$model"
              />
            </c-input-group>
          </c-stack>
          <c-button
            w="100%"
            h="60px"
            borderRadius="lg"
            size="lg"
            variant="solid"
            type="submit"
            class="auth__form__button auth__form__button--primary"
            :disabled="$v.forgotPasswordForm.$invalid || isResettingPassword"
          >
            Reset Password
            <c-spinner
              ml="4"
              v-if="isResettingPassword"
              color="#fff"
              thickness="3px"
            />
          </c-button>
        </form>
      </c-stack>
      <c-flex direction="column">
        <HeroGraphic :image="'signin-graphic.webp'" :position="'right'" />
      </c-flex>
    </div>
  </div>
</template>

<script>
import { resetPassword } from '@/services/auth';
import { required, email } from 'vuelidate/lib/validators';

import HeroGraphic from './components/HeroGraphic.vue';

export default {
  components: {
    HeroGraphic,
  },
  validations: {
    forgotPasswordForm: {
      email: {
        email,
        required,
      },
    },
  },
  data() {
    return {
      forgotPasswordForm: {
        email: null,
      },
      isResettingPassword: false,
    };
  },
  methods: {
    async onForgotPasswordFormSubmit() {
      this.isResettingPassword = true;
      try {
        const res = await resetPassword({
          email: this.forgotPasswordForm.email,
          url: `${document.location.origin}/auth/set-password`,
        });
        this.$toast({
          title: 'Success',
          description: res.data.result,
          status: 'success',
          position: 'top',
          duration: 5000,
        });
        this.isResettingPassword = false;
      } catch (e) {
        this.isResettingPassword = false;
        console.log(e);
      }
    },
  },
};
</script>

<style lang="scss" scoped>
.auth {
  &__form {
    @apply px-0 w-full;
  }
  &__left {
    background-image: linear-gradient(
        to right top,
        rgba(0, 0, 0, 0.7),
        rgba(0, 0, 0, 0.7)
      ),
      url('./../../assets/img/auth-left-male.jpg');
  }
}
</style>
